import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext, WebsiteDetailsContext } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import history from '../../history'
import FooterFixed from './FooterFixed'
import { Document, Page } from 'react-pdf';

function Achievement() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;

    const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-required-pages-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;

                    setwebsiteDetails({ "template_identity": result.template_identity, "extra_seo": result.extra_seo_details && result.extra_seo_details.original });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    const websitecolor = websiteDetails && websiteDetails.template_identity[0].theme_color

    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });

    const onDocumentLoadSuccess=( numPages ) =>{
        setNumPages(numPages);
      }

    return (
        <div>
            {(websiteDetails === undefined) ? <div className="loader"></div> : <Header />}
            <MetaTags >
                <meta charSet="UTF-8" />
                <title>Maha Bodhi Hotel</title>
                <meta name="description" content="Maha Bodhi Hotel" />
            </MetaTags>

            <section className="banner_area" style={{ backgroundImage: "url(" + require("../background/Exterior.JPG") + ")" }}>
                <div className="container">
                    <div className="banner_inner_content">
                        <h3 style={{ fontFamily: websitefont }}>Certificate of Excellence</h3>
                        <ul>
                            <li className="active"><a onClick={() => history.push(config.PATH + 'home')} style={{ fontFamily: websitefont }} >Home</a></li>
                            <li style={{ fontFamily: websitefont, color: "white" }}><a>Certificate of Excellence</a></li>
                        </ul>
                    </div>
                </div>
            </section>

           

             
            <h2 style={{ font: websitefont, color: "#b62a51", padding: "40px 0px",textAlign:"center" }}>Appreciation Letter from Myanmar President</h2>
            <div className="iframe-achievement set-margin set-padding set-border set-box-shadow center-block-horiz">
                <div className="responsive-wrapper responsive-wrapper-achievement">
                    <iframe src="https://bookingjini.info/pdfjs-2.4.456/web/viewer.html?file=https://bookingjini.info/AppreciationtoMahaBodhihotel.pdf">
                    </iframe>

                </div>
            </div>



            <h2 style={{ font: websitefont, color: "#b62a51", padding: "40px 0px",textAlign:"center" }}>Appreciation to Maha Bodhi hotel</h2>
            <div className="iframe-achievement set-margin set-padding set-border set-box-shadow center-block-horiz">
                <div className="responsive-wrapper responsive-wrapper-achievement">
                    <iframe src="https://bookingjini.info/pdfjs-2.4.456/web/viewer.html?file=https://bookingjini.info/AppreciationtoMahaBodhihotel.pdf">
                    </iframe>

                </div>
            </div>


            <h2 style={{ font: websitefont, color: "#b62a51", padding: "40px 0px",textAlign:"center" }}>Letter of Appreciation from BEL</h2>
            <div className="iframe-achievement set-margin set-padding set-border set-box-shadow center-block-horiz">
                <div className="responsive-wrapper responsive-wrapper-achievement">
                    <iframe src="https://bookingjini.info/pdfjs-2.4.456/web/viewer.html?file=https://bookingjini.info/LetterofAppreciation-BEL.pdf">
                    </iframe>

                </div>
            </div>

            <h2 style={{ font: websitefont, color: "#b62a51", padding: "40px 0px",textAlign:"center" }}>Certificate of Excellence</h2>
            <div className="iframe-achievement set-margin set-padding set-border set-box-shadow center-block-horiz">
                <div className="responsive-wrapper responsive-wrapper-achievement">
                    <iframe src="https://bookingjini.info/pdfjs-2.4.456/web/viewer.html?file=https://bookingjini.info/CertificateofExcellence-MahaBodhi.pdf">
                    </iframe>

                </div>
            </div>


            <h2 style={{ font: websitefont, color: "#b62a51", padding: "40px 0px",textAlign:"center" }}>Letter of Appreciation</h2>
            <div className="iframe-achievement set-margin set-padding set-border set-box-shadow center-block-horiz">
                <div className="responsive-wrapper responsive-wrapper-achievement">
                    <iframe src="https://bookingjini.info/pdfjs-2.4.456/web/viewer.html?file=https://bookingjini.info/LETTER-OF-APPRECIATION.pdf">
                    </iframe>

                </div>
            </div>
    


            {(websiteDetails === undefined) ? '' : <FooterFixed />}
            {(websiteDetails === undefined) ? '' : <Footer />}
        </div>
    )
}

export default Achievement;