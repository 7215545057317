import React, { useState, useContext,useEffect } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
  } from "react-google-maps";
  import { compose, withProps } from "recompose";
  import FooterFixed from './FooterFixed'

function CommonPage() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-commonpage-data/' + company_id + '/' + template_id
                        // config.apiEndpoint1 + '/userwb/get-commonpage-data/1685/2'
                    );
                    const json = await staticPageData.json();
                    const result = json.data;

                    setwebsiteDetails({ "template_identity":result.template_identity,"nearby_places":result.nearby_places && result.nearby_places.original});

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

   
     const websitefont=websiteDetails && websiteDetails.template_identity[0].theme_font

    var split_url
    const parseURLParams=(url) =>{
        var queryStart = url.indexOf("?")+1 ,
            queryEnd = url.indexOf("#") + 1 || url.length + 1,
            query = url.slice(queryStart, queryEnd - 1);
            split_url=query
    }

    let url = window.location.href;
    let get_encoded_url = parseURLParams(url);
     
    const initialValue = [];
    const [hotelInfo, sethotelInfo] = useState(initialValue);
    useEffect(() => {
        
        (async function() {
          try {
            const hotelData = await fetch(
                config.apiEndpoint +'/userwb/get-hotel-details/'+split_url
            );
            const json = await hotelData.json();
            const result = json.data.hotel_details;
            if(result.room_details.original.data.length>3){
                let all_room_details=result.room_details.original.data;
                var extra_room_details=all_room_details.slice(0, 3)
                sethotelInfo({"hotelInfo":result,"extra_room_details":extra_room_details});
              }
              else{
                sethotelInfo({"hotelInfo":result,"extra_room_details":result.room_details.original.data});
 
              }
          } catch (e) {
            console.error(e);
          }
        })();
      }, [split_url]);
      const [scrolled,setscroll]=useState(false)
    if(scrolled===false){
        window.scrollTo(0,0)
        }
        
    window.addEventListener('scroll', function() {
        setscroll(true);
    });
    return (
        <div >
            {(hotelInfo === 0)?<div className="loader"></div>:''}
            <Header/>
            {(hotelInfo === 0) ? '' :  hotelInfo && hotelInfo.hotelInfo &&
            <section className="banner_area" style={{ backgroundImage: "url(" + config.imageEndpoint+"/public/"+hotelInfo.hotelInfo.image_name[0].image_name+ ")" }}>
                <div className="container">
                    <div className="banner_inner_content">
                        <h3 >About <span  style={{fontFamily:websitefont,color:"#ffb606"}}>{hotelInfo.hotelInfo.hotel_name}</span></h3>
                        {/* <ul>
                            <li className="active"><a href="/home" style={websiteDetails.template_identity[0].theme_color}>Home</a></li>
                            <li><a style={{ color: 'white' }}>Activities</a></li>
                        </ul> */}
                    </div>
                </div>
            </section>
            }
            {(hotelInfo === 0) ? '' :  hotelInfo && <AboutCommon aboutcommon={hotelInfo} />}
            {(hotelInfo === 0) ? '' :  hotelInfo && <RoomCommon roomcommon={hotelInfo} />}
            {(hotelInfo === 0) ? '' :  hotelInfo.hotelInfo && <MyMapComponent isMarkerShown 
              lat={parseFloat(hotelInfo.hotelInfo.latitude)}
              lng={parseFloat(hotelInfo.hotelInfo.longitude)}/>}    
              {(websiteDetails === undefined) ? '' : <FooterFixed/>}       
             {(websiteDetails === undefined)?'':<Footer/>}

        </div>
    )
}
function RoomCommon(props) {

    return (
        <div>
        <section className="explor_room_area explore_room_list">
                <div className="container">
                <div className="explor_title row m0">
                            <div className="pull-left">
                                <div className="left_ex_title">
                                    <h2 >Explor Our <span>rooms</span></h2>
                                    <p>choose room according to budget</p>
                                </div>
                            </div>
                            <div className="pull-right">
                                <Link className="about_btn_b" to={config.PATH + '/roomlist'}
                                 >view all rooms</Link>
                            </div>
                        </div>
                    <div className="row explor_room_item_inner">

                        {props.roomcommon && props.roomcommon.extra_room_details && props.roomcommon.extra_room_details.map(function (item,i) {
                            let value = item.description.substr(0, item.description.indexOf('.'));
                            return (
                                <div className="col-md-4 col-sm-6" key={i}>
                                    <div className="explor_item">
                                        <Link className="room_image" to={{
                                            pathname: config.PATH+'/roomdetail',
                                            search:'?'+(item.room_type +'|'+item.description+'|'+item.rack_price),
                                            roomdetails: item
                                        }}>
                                            <img src={config.imageEndpoint + '/public/' + item.room_image[0]} alt="" style={{ height: 205, width: 600 }} />
                                        </Link>
                                        <div className="explor_text">
                                        <Link to={{pathname:config.PATH+"/roomdetail",
                                         search:'?'+(item.room_type +'|'+item.description+'|'+item.rack_price),
                                         roomdetails: item,
                                        }}><h4 >{item.room_type}</h4></Link>
                                            <ul>
                                                <li><a >{value}.</a></li>
                                            </ul>
                                            <div className="explor_footer">
                                                <div className="pull-left">
                                                    <h3 >₹{item.rack_price}<span>/ Night</span></h3>
                                                </div>
                                                <div className="pull-right">
                                                    <Link className="book_now_btn"  to={{
                                                        pathname: config.PATH+'/roomdetail',
                                                        search:'?'+(item.room_type +'|'+item.description+'|'+item.rack_price),
                                                        roomdetails: item
                                                    }}>View details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}
   
function AboutCommon(props) {
    let about = props.aboutcommon.hotelInfo
    return (
        <section className="introduction_area resort_story_area" style={{marginTop:"62px"}}>
        <div className="container">
            <div className="introduction_inner introduction_img">
                {/* <div className="col-md-5">
                    <a className="introduction_img"> */}
                        {props.aboutcommon && props.aboutcommon.hotelInfo &&
                        <img className="about_img" src={config.imageEndpoint + '/public/'+props.aboutcommon.hotelInfo.image_name[0].image_name} alt=""  style={{width:"450px"}}/>
                        }
                    {/* </a>
                </div> */}
                {/* <div className="col-md-7"> */}
                    <div className="introduction_left_text">
                        <div className="resort_title">
                            <h2 >our resort <span>story</span></h2>
                            <h5 >give best service to our customers</h5>
                        </div>
                        {props.aboutcommon && props.aboutcommon.hotelInfo &&
                        <p  dangerouslySetInnerHTML={{ __html: about.hotel_description }}></p>
                        }
                        <Link className="about_btn_b" to={config.PATH+'/contact'}
                        >contact us</Link>
                    </div>
                </div>
            </div>
        {/* </div> */}
    </section>
    )
};

const MyMapComponent = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvbN2yXDGSnDIMmUp9JByD39hIsJhDKok&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: props.lat, lng: props.lng }}>
      {props.isMarkerShown && (
        <Marker position={{ lat: props.lat, lng:  props.lng  }} />
      )}
    </GoogleMap>
  ));
export default CommonPage;