import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext, WebsiteDetailsContext } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import logo from '../footer-logo.png'
import config from '../../../src/config.json'
import history from '../../history';


function Footer() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    const [hotelname, setHotelName] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-footer-data/' + company_id + '/' + template_id

                    );
                    const json = await hotelData.json();
                    const result = json.data;
                    var check_special_char = result && result.hotel_menu_details && result.hotel_menu_details[0].hotel_name.replace(/[^a-zA-Z0-9 ]/g, "");
                    var check_space = check_special_char.replace(/ +/g, "_");
                    var final_type = check_space.toLowerCase();
                    var all_menu_details = {
                        "footer": {
                            "footer_menus": result.footer_menu_details,
                        }
                    }


                    let phone_number_hotel
                    let emailIdHotel
                    let numbers=[];
                    let emails=[];
                    if (result && result.hotel_details) {
                        if (result.hotel_details.original[0].mobile.includes(',')) {
                            phone_number_hotel = result.hotel_details.original[0].mobile.slice(result.hotel_details.original[0].mobile.indexOf('-')+1, result.hotel_details.original[0].mobile.indexOf(','));

                        } else {
                            phone_number_hotel = result.hotel_details.original[0].mobile;
                        }


                        if (result.hotel_details.original[0].email_id.includes(',')) {
                            emailIdHotel = result.hotel_details.original[0].email_id.substr(0, result.hotel_details.original[0].email_id.indexOf(','));
                        } else {
                            emailIdHotel = result.hotel_details.original[0].email_id;
                        }

                        numbers=result.hotel_details.original[0].mobile.split(',');

                        emails=result.hotel_details.original[0].email_id.split(',');
                    }


                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity, "hotel_details": result && result.hotel_details, "hotel_menus": result && result.hotel_menu_details, "hotel_services": result.service_details && result.service_details.original, 'email': emailIdHotel, "phone": phone_number_hotel,"phone_numbers":numbers,"emails":emails});
                    setHotelName(result && result.hotel_menu_details && final_type);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])


    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    var hotelDetails = websiteDetails && websiteDetails.hotel_details.original[0]

    const redirectTo = (page) => {
        if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if (page_url.includes("www")) {
                    page_url = page_url.trimStart()
                    window.open("http://" + page_url)
                }

                else {
                    history.push(config.PATH + page_url);
                }
            }
        }
    }



    return (
        <div className=''>
            <footer className="footer_area">
                <div className="footer_widget_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-xs-6">
                                <aside className="f_widget about_widget">
                                    <a onClick={() => history.push(config.PATH + 'home')}>
                                        {websiteDetails && websiteDetails.template_identity &&
                                            <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt="" />}
                                    </a>
                                    <div className="ab_wd_list" style={{ color: "#757575" }}>
                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-map-marker"></i>
                                            </div>
                                            <div className="media-body">
                                                {websiteDetails && websiteDetails.hotel_details &&
                                                    <h4 style={{ fontFamily: websitefont, marginTop: "0px" }}><b>{websiteDetails.hotel_details.original[0].hotel_address}</b></h4>
                                                }
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-phone"></i>
                                            </div>
                                            <div className="media-body">
                                            <h4 style={{ fontFamily: websitefont, marginTop: "0px" }}>
                                                {websiteDetails && websiteDetails.phone_numbers.length>0 && websiteDetails.phone_numbers.map(function(items,i){

                                                    return(
                                                    <span key={i}><a href={"tel:" + items} style={{ color: "inherit", fontWeight: "700" }} >{items}</a>{ i< websiteDetails.phone_numbers.length-1 &&<span>,</span>}</span>
                                                    )
                                                })}
                                                </h4>
                                               
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-left">
                                                <i className="fa fa-envelope-o"></i>
                                            </div>
                                            <div className="media-body">
                                                

                                                <h4 style={{ fontFamily: websitefont, marginTop: "0px" }}>
                                                    {websiteDetails && websiteDetails.emails.length > 0 && websiteDetails.emails.map(function (items, i) {

                                                        return (
                                                            <span key={i}><a href={"mailto:" + items} style={{ color: "inherit", fontWeight: "700" }} >{items}</a>{ i< websiteDetails.phone_numbers.length-1 &&<br />}</span>
                                                        )
                                                    })}                                                    

                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </aside>

                                <ul className="footer-links-social">
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.facebook_link) ? hotelDetails.facebook_link : "#"} ><i className="fa fa-facebook"></i></a></li>
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.instagram_link) ? hotelDetails.instagram_link : "#"} ><i className="fa fa-instagram"></i></a></li>
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.tripadvisor_link) ? hotelDetails.tripadvisor_link : "#"} ><i className="fa fa-tripadvisor"></i></a></li>
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.twitter_link) ? hotelDetails.twitter_link : "#"}><i className="fa fa-twitter"></i></a></li>
                                </ul>

                            </div>
                            <div className="col-md-4 col-xs-6">
                                <aside className="f_widget link_widget">
                                    <div className="f_title">
                                        <h3 style={{ fontFamily: websitefont }}>Menu</h3>
                                    </div>
                                    {/* <ul>
                                        <li><a style={{ fontFamily: websitefont, cursor: "pointer" }}
                                            onClick={() => history.push(config.PATH + 'home')}>- Home
                                            </a></li>

                                        <li><a style={{ fontFamily: websitefont, cursor: "pointer" }}
                                            onClick={() => history.push(config.PATH + 'room')}>- Rooms
                                            </a></li>
                                        <li><a style={{ fontFamily: websitefont, cursor: "pointer" }}
                                            onClick={() => history.push(config.PATH + 'contact')}>- Contact Us
                                            </a></li>
                                    </ul> */}
                                    {websiteDetails && websiteDetails.menu_details && websiteDetails.menu_details.footer.footer_menus.map(function (item, i) {
                                        if (item.page_id === 1) {
                                            item.page = item.page_url;
                                        }
                                        return (
                                            <ul key={i}>
                                                <li><a style={{ fontFamily: websitefont, cursor: "pointer" }}
                                                    onClick={() => redirectTo(item)}>- {item.menu_name}
                                                </a></li>
                                            </ul>)
                                    })}
                                </aside>
                            </div>
                            <div className="col-md-4 col-xs-6">
                                <aside className="f_widget link_widget">
                                    <div className="f_title">
                                        <h3 style={{ fontFamily: websitefont }}>Our amenities</h3>
                                    </div>
                                    <ul>
                                        <div className="row">
                                            {websiteDetails && websiteDetails.hotel_services && websiteDetails.hotel_services.map(function (item, i) {
                                                var send_title = item.title.replace(/[^a-zA-Z0-9 ]/g, "");
                                                send_title = send_title.replace(/ +/g, "_");
                                                send_title = send_title.toLowerCase();
                                                if (i % 2 === 0) {
                                                    return (
                                                        <div className="col-md-6" key={i}>
                                                            <li>
                                                                <Link to={{
                                                                    pathname: config.PATH + 'page',
                                                                    search: hotelname + '/' + send_title
                                                                }} style={{ fontFamily: websitefont }}>- {item.title}</Link>
                                                            </li>
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div className="col-md-6" key={i}>
                                                            <li>
                                                                <Link to={{
                                                                    pathname: config.PATH + 'page',
                                                                    search: hotelname + '/' + send_title
                                                                }} style={{ fontFamily: websitefont }}>- {item.title}</Link>
                                                            </li>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </ul>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_copyright_area">
                    <div className="container">
                        <div className="pull-left">
                            {websiteDetails && websiteDetails.hotel_details &&
                                <h4>Copyright © {websiteDetails.hotel_details.original[0].hotel_name} All rights reserved. </h4>
                            }
                        </div>
                        <div className="pull-right">
                            <h4>Created by: <a href="https://bookingjini.com/" target="_blank"><img src={config.apiEndpoint + '/public/templateimage/jini_logo.png'} style={{ marginTop: "-10px", width: "120px" }} /></a></h4>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Footer