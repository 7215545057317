import React, { useState, useContext,useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext, WebsiteDetailsContext } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import history from '../../history'
import FooterFixed from './FooterFixed'

function Sightseeing(){
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
   
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-required-pages-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
        
                    setwebsiteDetails({ "template_identity":result.template_identity, "extra_seo":result.extra_seo_details && result.extra_seo_details.original});
                   
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])
    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    const websitecolor = websiteDetails && websiteDetails.template_identity[0].theme_color

    const [scrolled,setscroll]=useState(false)
    if(scrolled===false){
        window.scrollTo(0,0)
        }
        
    window.addEventListener('scroll', function() {
        setscroll(true);
    });
    return(
        <div>
            {(websiteDetails === undefined) ? <div className="loader"></div> : <Header />}
            <MetaTags >
                <meta charSet="UTF-8" />
                <title>Maha Bodhi Hotel</title>
                <meta name="description" content="Maha Bodhi Hotel" />
            </MetaTags>

            <section className="banner_area" style={{ backgroundImage: "url(" + require("../background/1.jpg") + ")" }}>
                <div className="container">
                    <div className="banner_inner_content">
                        <h3 style={{ fontFamily: websitefont }}>Sightseeing</h3>
                        <ul>
                            <li className="active"><a onClick={() => history.push(config.PATH + 'home')} style={{ fontFamily: websitefont }} >Home</a></li>
                            <li style={{ fontFamily: websitefont, color: "white" }}><a>Sightseeing</a></li>
                        </ul>
                    </div>
                </div>
            </section>
            <div className="container">
            <div className="room_service_list">
            <div className="main_big_title">
                    <h2>Nearby <span>Attractions</span></h2>
                    <br />
                </div>
                    <div className="row room_service_list_inner services-listing">
                        <div className="col-sm-5 col-md-offset-right-1">
                            <div className="resot_list">
                                <ul>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Mahabodhi Temple <span style={{float:"right"}}>4.1 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Bodhi Tree <span style={{float:"right"}}>4.2 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Kesariya <span style={{float:"right"}}>219 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Great Buddha Statue <span style={{float:"right"}}>3.9 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>JALMANDIR <span style={{float:"right"}}>90 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Gaya Vishnupad <span style={{float:"right"}}>6 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Barabar Caves <span style={{float:"right"}}>44 km</span></li>
                               
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-offset-right-1">
                            <div className="resot_list">
                                <ul>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Nalanda <span style={{float:"right"}}>106 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Vaishali <span style={{float:"right"}}>144 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Patna <span style={{float:"right"}}>110 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Kushinagar <span style={{float:"right"}}>323 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>VIKRAMSHILA RUINS <span style={{float:"right"}}>293 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Brahma Yoni <span style={{float:"right"}}>9.6 km</span></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i>Burmese Temple <span style={{float:"right"}}>3.3 km</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {(websiteDetails === undefined) ? '' : <FooterFixed/>}
        {(websiteDetails === undefined) ? '' : <Footer />}
        </div>
    )
}

export default Sightseeing;
